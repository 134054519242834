<template>
  <div class="backboard">
    <img src="../img/content.png" class="image content-img" />
    <div class="button">
      <van-button
        round
        block
        type="primary"
        native-type="submit"
        :loading="loading"
        @click="wxAuth"
      >
        开始报名
      </van-button>
    </div>
    <img src="../img/bottom.png" class="image bottom-img" />
  </div>
</template>

<script>
import { wxLogin } from "@/api/pclogin/uia";

export default {
  data() {
    return {
      loading: false,
    };
  },
  props: {
    visible: Boolean,
  },

  methods: {
    wxAuth() {
      const appid = process.env.VUE_APP_WXAPPID;
      const redirect_uri = encodeURIComponent(window.location.href);
      const state = "";
      const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=${state}#wechat_redirect`;
      window.location = url;
    },
    getToken() {
      const { code } = this.$route.query;
      if (code) {
        this.loading = true;
        wxLogin({
          code: code,
        })
          .then((res) => {
            this.loading = false;
            if (res.code != 200) return;
            if (typeof res.data === "string") {
              this.$emit("success", {
                openid: res.data,
              });
            } else {
              this.$emit("success", {
                openid: res.data.openid,
              });
            }
          })
          .catch((e) => {
            this.loading = false;
          });
      }
    },
  },
  created() {
    this.getToken();
  },
};
</script>

<style lang="scss" scoped>
.backboard {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  background-color: linear-gradient(245deg, #0a6d93, #2e9e78);
  background: url(../img/bg.png) no-repeat;
  background-size: 100% 100%;
  .title {
    margin-top: 1rem;
    color: #666;
    font-size: 0.34rem;
    text-align: center;
    white-space: nowrap;
    line-height: 0.48rem;
  }
  .image {
    width: 100%;
    align-self: center;
    &.content-img {
      width: 100%;
      padding: 1rem 0.5rem;
    }
    &.bottom-img {
      padding: 1rem 0.5rem 0.1rem 0.5rem;
    }
  }
  .button {
    width: 50%;
    .van-button {
      background: linear-gradient(45deg, #25987b, #7d5386);
      border: 1px solid #14978d;
    }
  }
}
</style>
