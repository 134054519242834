<template>
  <!-- 人才公寓需求数量统计 -->
  <section class="form-wrapper">
    <div>
      <van-form ref="form">
        <div class="header card">
          <div class="title">航空港区人才活动周报名通道</div>
          <div class="notices">
            <p class="notice-item">
              航空港区5月22日—5月26日举办“爱创汇才
              航向未来”航空港区“人才活动周”，诚邀广大人才朋友们积极参加诚邀广大人才朋友们积极参加。
            </p>
            <p class="notice-item">
              活动周各项行程安排及其他咨询，可关注“
              <span class="link" @click="goPublic">河南郑州航空港人才</span>
              ”公众号知悉。
            </p>
          </div>
          <van-field
            v-model.trim="form.cmpyName"
            label="企业名称"
            placeholder="请输入企业名称"
            required
            maxlength="200"
          />
          <van-field
            v-model.trim="form.userName"
            label="联系人"
            placeholder="请输入联系人"
            required
            maxlength="20"
          />
          <van-field
            v-model.trim="form.contactPhone"
            label="联系方式"
            placeholder="请输入联系方式"
            required
            maxlength="11"
          />
        </div>

        <!--  参会人员信息  -->
        <div>
          <div
            v-for="(item, i) in form.details"
            :key="i"
            class="plot-wrapper card"
          >
            <p class="plot-title">
              <span> # {{ i + 1 }} </span>
              <span
                @click="handleDelPlot(i)"
                v-show="form.details.length > 1"
                class="del-btn"
              >
                删除参会人员
              </span>
            </p>
            <van-field
              v-model.trim="item.userName"
              label="参会人员姓名"
              placeholder="请输入参会人员姓名"
              maxlength="200"
              required
            />
            <van-field
              v-model.trim="item.position"
              label="职务"
              placeholder="请输入职务"
              required
            />
            <van-field
              v-model.trim="item.contact"
              label="联系方式"
              placeholder="请输入联系方式"
              required
              maxlength="11"
            />
            <van-field
              name="activitiesCheckboxGroup"
              required
              label="参会活动（多选）"
            >
              <template #input>
                <van-checkbox-group
                  v-model="item.activityType"
                  ref="checkboxGroup"
                >
                  <van-checkbox
                    v-for="i in activityList"
                    :name="i"
                    :key="i"
                    :disabled="i.indexOf('5月23日') > -1"
                    class="checkbox-activities"
                    >{{ i }}</van-checkbox
                  >
                </van-checkbox-group>
              </template>
            </van-field>

            <!-- <van-button type="primary" @click="checkAll">全选</van-button>
            <van-button type="primary" @click="toggleAll">反选</van-button> -->
          </div>
          <div>
            <van-button
              block
              plain
              icon="plus"
              type="primary"
              size="small"
              @click="handleAddPlot"
            >
              新增参会人员
            </van-button>
          </div>
        </div>
      </van-form>
    </div>
  </section>
</template>

<script>
import Schema from "async-validator";
import formRules from "./rules";
import activityList from "./activities";
import cloneDeep from "lodash/cloneDeep";
import Cookies from "js-cookie";
import { Dialog } from "vant";
//企业表单
const cmpyForm = {
  activityType: [],
  contact: "",
  position: "",
  userName: "",
};
export default {
  data() {
    return {
      activityList: activityList,
      form: {
        cmpyName: "",
        contactPhone: "",
        userName: "",
        details: [cloneDeep(cmpyForm)],
      },
    };
  },
  props: {
    defaultForm: Object,
  },
  watch: {
    defaultForm(val) {
      this.form = this.defaultForm;
    },
  },
  computed: {},
  mounted() {},
  methods: {
    handleAddPlot() {
      this.form.details.push(cloneDeep(cmpyForm));
    },
    handleDelPlot(index) {
      if (this.form.details.length == 1) {
        this.$toast("最后一条不能删除");
        return;
      }
      Dialog.confirm({
        title: "提示",
        message: "确认要删除当前参会人员？",
      }).then(() => {
        this.form.details.splice(index, 1);
      });
    },
    validate() {
      const validator = new Schema(formRules);
      return new Promise((resolve, reject) => {
        validator
          .validate(this.form, (errors) => {
            if (errors && errors.length) {
              if (errors[0].field.indexOf("details") > -1) {
                let num = Number(errors[0].field.split(".")[1]) + 1;
                this.$toast("#" + num + "：" + errors[0].message);
              } else {
                this.$toast(errors[0].message);
              }
            }
          })
          .then(async () => {
            resolve({
              ...this.form,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    goPublic() {
      let ua = navigator.userAgent.toLowerCase();
      let isWXWork = ua.match(/wxwork/i) == "wxwork";
      let isWeixin =
        !isWXWork && ua.match(/micromessenger/i) == "micromessenger";
      if (isWeixin) {
        window.location.href =
          "https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzkwMjQxNTI4Mw==#wechat_redirect";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  background-color: #fff;
  border-radius: 0.2rem;
  padding: 0.2rem 0.3rem;
  overflow: hidden;
  margin-bottom: 0.3rem;
}
.title {
  font-size: 0.36rem;
  margin: 0.3rem 0;
  text-align: center;
  font-weight: bold;
}
.form-wrapper {
  // margin-top: 0.2rem;
  // background: #fff;
  .fam-btns {
    padding: 0.3rem;
    margin-bottom: 0.3rem;
    border-bottom: 1px solid #eee;
    .btn {
      width: 1.5rem;
      margin-right: 0.15rem;
    }
  }
}
.house-wrapper {
  padding: 0.1rem 0.15rem;
  .house-title {
    margin: 0.3rem 0;
    .add-btn {
      margin-left: 0.2rem;
    }
    // display: flex;
    // justify-content: space-between;
  }
  .house-form {
    padding-left: 0.15rem;
    overflow: auto;
    margin-bottom: 0.2rem;
    border-bottom: 1px solid #dfdfdf;
    .del-house-btn {
      display: block;
      // margin-bottom: 0.2rem;
      padding: 0.2rem 0;
    }
  }
  .house-form-title {
    margin: 0.1rem 0;
    display: flex;
    justify-content: space-between;
  }
}
.plot-wrapper {
  // border-bottom: 1px solid #dfdfdf;
  padding: 0.2rem 0;
  margin-bottom: 0.2rem;
  .plot-title {
    padding: 0 0.15rem;
    display: flex;
    justify-content: space-between;
  }
}
.add-btn {
  color: #3c37d2;
}
.del-btn {
  color: red;
}
.notices {
  margin-top: 0.2rem;
  padding: 0.2rem;
  background-color: #fff;
  .notice-item {
    font-size: 0.25rem;
    line-height: 1.8;
    // color: #ff0000;、
    text-indent: 2em;
    margin-bottom: 0.15rem;
  }
  .link {
    color: blue;
  }
}
.checkbox-activities {
  margin-bottom: 0.15rem;
}
</style>
