const rules = {
  cmpyName: [
    {
      required: true,
      message: "请输入企业名称",
    },
    {
      max: 200,
      message: "企业名称最多200字",
    },
  ],
  contactPhone: [
    {
      required: true,
      message: "请输入联系方式",
    },
    {
      pattern:
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
      message: "请检查手机号格式",
    },
  ],
  userName: [
    {
      required: true,
      message: "请输入联系人",
    },
    {
      max: 28,
      message: "联系人最多28字",
    },
  ],

  details: {
    type: "array",
    required: true,
    defaultField: {
      type: "object",
      fields: {
        userName: [
          {
            required: true,
            message: "请输入参会人员姓名",
          },
          {
            max: 20,
            message: "参会人员姓名最多20字",
          },
        ],
        position: [
          {
            required: true,
            message: "请输入职务",
          },
          {
            max: 64,
            message: "职务最多64字",
          },
        ],
        contact: [
          {
            required: true,
            message: "请输入联系方式",
          },
          {
            pattern:
              /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
            message: "请检查手机号格式",
          },
        ],
        activityType: [
          {
            type: "array",
            required: true,
            message: "请选择参会活动",
          },
        ],
      },
    },
  },
};
export default rules;
