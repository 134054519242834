<template>
  <div>
    <template v-if="showForm">
      <Result v-if="showResult" @edit="edit"></Result>
      <div v-else class="wrapper">
        <div class="form">
          <Form ref="form" :defaultForm="defaultForm" />
        </div>
        <div class="btn">
          <van-button
            :loading="loading"
            type="primary"
            block
            @click="handleSubmit"
          >
            提交
          </van-button>
        </div>
      </div>
    </template>
    <wechatUserForm
      v-if="showAuth"
      :visible="showAuth"
      @success="handleValSuccess"
    />
  </div>
</template>

<script>
import Form from "./components/Form.vue";
import Result from "./components/result.vue";
import wechatUserForm from "./components/wechatUserForm.vue";
import AuthMixin from "../mixin/wechatUserAuth";
import activityList from "./components/activities";
export default {
  data() {
    return {
      activityList: activityList,
      loading: false,
      showResult: false,
      defaultForm: null,
    };
  },
  mixins: [AuthMixin],
  components: { Form, wechatUserForm, Result },
  created() {},
  methods: {
    getDefaultForm() {
      this.$toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true,
      });
      return this.$store
        .dispatch("houseSurvey/getTalentActivities", {
          openId: this.openId,
        })

        .then((res) => {
          if (res.data) {
            this.showResult = true;
            this.$toast.clear();
          }
        })
        .catch((err) => {
          setTimeout(() => {
            this.$toast.clear();
          }, 500);
        });
    },
    // 进入修改模式
    edit() {
      this.showResult = false;
      this.$store
        .dispatch("houseSurvey/getTalentActivities", {
          openId: this.openId,
        })

        .then((res) => {
          if (res.data) {
            res.data.details = res.data.details.filter((item) => {
              item.activityType = item.activityType.split(",");
              return item;
            });
            this.defaultForm = res.data;
            this.$toast.clear();
          }
        })
        .catch((err) => {
          setTimeout(() => {
            this.$toast.clear();
          }, 500);
        });
    },
    //oppenid验证成功
    handleValSuccess(form) {
      if (form.openid) {
        this.suthSuccess(form.openid);
      }
    },
    //提交表单
    handleSubmit() {
      this.$refs.form.validate().then((form) => {
        let crtForm = this.beforeSubmit(form);
        this.loading = true;
        this.$toast.loading({
          message: "提交中",
          forbidClick: true,
        });
        this.$store
          .dispatch("houseSurvey/addTalentActivities", {
            ...crtForm,
            openId: this.openId,
          })
          .then((res) => {
            this.$toast.clear();
            this.loading = false;
            this.showResult = true;
          })
          .catch((err) => {
            setTimeout(() => {
              this.$toast.clear();
              this.loading = false;
            }, 500);
          });
      });
    },
    // 参会活动，用 | 连接
    beforeSubmit(form) {
      let crtForm = JSON.parse(JSON.stringify(form));
      crtForm.details = crtForm.details.filter((item) => {
        // 字典表更改时，删除不包含的旧字典表
        item.activityType = item.activityType.filter((value) => {
          if (activityList.indexOf(value) > -1) {
            return value;
          }
        });
        item.activityType = item.activityType.join(",");
        return item;
      });
      return crtForm;
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  background: #f3f3f3;
  padding: 0.2rem 0.15rem;
}

.form {
  margin-bottom: 0.5rem;
}
</style>
